<template>
    <div>
        <b-row class="content-header">
            <b-col class="content-header-left mb-2" cols="12" md="9">
                <b-row class="breadcrumbs-top">
                    <b-col cols="12">
                        <h2 class="float-left mb-0">Resellers</h2>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-card>
                    <b-card-text
                        >Voucher Code will be sent instantly to your email after
                        payment is successful.
                        <b-link :to="{ name: 'voucher' }"
                            >Redeem a voucher</b-link
                        >
                        to upgrade your account.
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col md="12" cols="12">
                <b-card title="Note">
                    <b-list-group class="list-group-circle text-left">
                        <b-list-group-item
                            >Please check host status page before making any
                            payment.</b-list-group-item
                        >
                        <b-list-group-item
                            >Check Daily size limits and Bandwidth usage
                            limits.</b-list-group-item
                        >
                        <b-list-group-item
                            >Filesize and Bandwidth limits can alter anytime
                            without any notice (increase or
                            decrease).</b-list-group-item
                        >
                        <b-list-group-item
                            >Once premium key is applied successfully then it
                            can't be revoked. Also no refunds are
                            possible.</b-list-group-item
                        >
                        <b-list-group-item>
                            Some hosts like Tezfiles, Filesfly, Elitefile... can
                            not download all files. Because there are some files
                            need pay or separate subscription for sites that
                            upload files to download it.
                        </b-list-group-item>
                    </b-list-group>
                </b-card>
            </b-col>
        </b-row>
        <b-row v-if="items.length > 0">
            <b-col md="4" v-for="item in items" :key="item.id">
                <b-card>
                    <a :href="item.url" target="_blank"
                        ><h4 class="text-primary mb-1">{{ item.name }}</h4></a
                    >
                    <b-card-text
                        ><strong>Email:</strong> {{ item.email }}</b-card-text
                    >
                    <b-card-text
                        ><strong>Payment methods:</strong>
                        {{ item.payment_methods }}</b-card-text
                    >
                </b-card>
            </b-col>
        </b-row>
        <p v-else>{{ message }}</p>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BListGroup,
    BListGroupItem,
} from "bootstrap-vue";
export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardText,
        BLink,
        BListGroup,
        BListGroupItem,
    },
    data() {
        return {
            items: [],
            message: "",
        };
    },
    created() {
        this.$store
            .dispatch("fetchResellers")
            .then((res) => {
                this.items = res.data.data;
                this.message = "We will update soon!";
            })
            .catch((err) => console.log(err));
    },
};
</script>

<style></style>
